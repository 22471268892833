import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "./reducers";

interface Participant {
  ageGroupName: string;
  name: string;
  participantId: number;
  gender: string;
}

interface Event {
  competitionEventId: number;
}

export interface NanduJudgingState {
  event: Event;
  participant: Participant;
  activeEvent: Event;
  activeParticipant: Participant;
}

const initialState = {} as NanduJudgingState;

export const slice = createSlice({
  name: "nanduJudging",
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<{ event: Event }>) => {
      state.event = action.payload.event;
    },
    setParticipant: (
      state,
      action: PayloadAction<{ participant: Participant }>
    ) => {
      state.participant = action.payload.participant;
    },
    setActiveEvent: (state, action: PayloadAction<{ event: Event }>) => {
      state.activeEvent = action.payload.event;
    },
    setActiveParticipant: (
      state,
      action: PayloadAction<{ activeParticipant: Participant }>
    ) => {
      state.activeParticipant = action.payload.activeParticipant;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEvent,
  setParticipant,
  setActiveEvent,
  setActiveParticipant,
} = slice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getEvent = (state: RootState) => state.nanduJudging.event;
export const getParticipant = (state: RootState) =>
  state.nanduJudging.participant;
export const getActiveEvent = (state: RootState) =>
  state.nanduJudging.activeEvent;
export const getActiveParticipant = (state: RootState) =>
  state.nanduJudging.activeParticipant;

export default slice.reducer;
